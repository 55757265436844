export default (filename, type, data) => {
  const blob =
    typeof File === 'function' ? new File([data], filename, { type: type }) : new Blob([data], { type: type })

  let a

  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    window.navigator.msSaveBlob(blob, filename)
  } else {
    const URL = window.URL || window.webkitURL
    const downloadUrl = URL.createObjectURL(blob)

    if (filename) {
      a = document.createElement('a')
      if (typeof a.download === 'undefined') {
        window.location = downloadUrl
      } else {
        a.href = downloadUrl
        a.download = filename
        document.body.appendChild(a)
        a.click()
      }
    } else {
      window.location = downloadUrl
    }

    setTimeout(function() {
      try {
        document.body.removeChild(a)
      } catch (e) {
        //no action
      }
      URL.revokeObjectURL(downloadUrl)
    }, 300)
  }
}
